{
  "unsubscribe_title": "Unsubscribe from emails",
  "unsubscribe_message": "Sorry to see you go.",
  "unsubscribe": "Unsubscribe",
  "cancel": "Cancel",
  "passwordResetSuccess": "Your password has been changed successfully.",
  "passwordResetError": "Unfortunately, there was a problem",
  "passwordResetTitle": "Set a new password",
  "new_password": "New password",
  "confirm_password": "Confirm new password",
  "save": "Save",
  "error": "Unfortunately, there was a problem",
  "unsubscribeSuccess": "The action was successful",
  "emailChangeLoading": "Email change loading...",
  "emailChangeSuccess": "The email was successfully changed",
  "emailChangeError": "Unfortunately, there was a problem changing the email",
  "loading": "Loading...",
  "deletionStatusError": "Error loading deletion status",
  "deletionStatusTitle": "Account Deletion Status",
  "deletionStatus": {
    "pending": "Your account deletion request is being processed",
    "Deleted": "Your account has been successfully deleted",
    "Failed to find user": "Failed to find user account",
    "Failed to check if user can be deleted": "Failed to verify deletion eligibility",
    "Failed to delete user": "Failed to complete deletion",
    "User cannot be deleted, maybe has active subscription": "Account cannot be deleted due to active subscription"
  }
}
